<template>
  <form class="signin-form form view-login-form">
    <div>
      <form-group :field="$v.account.email" :label="$t('references.email')" name="email">
        <el-input
            placeholder="Email"
            v-model.trim="account.email"
        >
        </el-input>
      </form-group>

      <form-group :field="$v.account.password" :label="$t('signin-form.password-label')" name="password">
        <el-input
            maxlength="8"
            type="number"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            :placeholder="$t('signin-form.password-placeholder')"
            v-model="account.password"
            @input="onPasswordInput"
            @change="$v.account.password.$touch()"
            autocomplete="off"
            :disabled="iinLoading"
            show-password
            @keyup.enter.native="submit(account)">
        </el-input>
      </form-group>
      <div class="row form-group mt-4">
        <div class="col-6">
          <el-checkbox :disabled="iinLoading">{{ $t('signin-form.remember-me') }}</el-checkbox>
        </div>
        <div class="col-6 text-right">
          <router-link to="/forget-password-teacher/email" tag="el-link" style="text-decoration: none"
                       :disabled="iinLoading">
            {{ $t('signin-form.forget-password') }}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <router-link
              to="/signup-teacher/first-step"
              tag="el-link"
              style="text-decoration: none"
              :disabled="iinLoading"
          >
            {{ $t('signin-form.dont-have-account') }} <span class="text-primary">{{ $t('signin-form.signup') }}</span>
          </router-link>
<!--          <a @click.prevent="registrationClosed" style="cursor: pointer">-->
<!--            {{ $t('signin-form.dont-have-account') }} <span class="text-primary">{{ $t('signin-form.signup') }}</span>-->
<!--          </a>-->
        </div>
      </div>
      <div class="form-group text-center pt-3">
        <el-button type="primary" @click.prevent="submit(account)" :loading="loading"
                   :disabled="iinLoading">{{ $t('signin-form.signin-button') }}
        </el-button>
      </div>
    </div>

  </form>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {required, minLength, email} from 'vuelidate/lib/validators'

export default {
  name: 'LoginForm',
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      iinLoading: false,
      loading: false,
      iinArray: [],
      account: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onPasswordInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      ;
      this.account.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    addOptionsToSelect() {
      this.iinArray = [];
      let data = JSON.parse(sessionStorage.getItem('children'));

      data.forEach(element => {
        let item = {};
        item.label = element.last_name + " " + element.first_name;
        item.value = element.iin;
        this.iinArray.push(item);
      });
      sessionStorage.removeItem('children');
    },
    // onGetIin() {
    //   if (!this.$v.account.phone.$invalid) {
    //     if (this.account.phone != '') {
    //       this.iinLoading = true;
    //       this.getIin({
    //         phone: this.account.phone
    //       }).then((res) => {
    //         this.iinLoading = false;
    //         this.addOptionsToSelect()
    //       }).catch((e) => {
    //         this.iinLoading = false;
    //         this.phone = '';
    //         console.log(e);
    //       })
    //     }
    //   }
    // },
    // onGetFio() {
    //   this.$http.get(API_ROOT + `/api/auth-available/${this.account.iin}`)
    //       .then((res) => {
    //         this.iinLoading = false;
    //       })
    //       .catch((e) => {
    //         if(e.body.region.length==0){
    //           Vue.toastr({
    //             message: this.$t('error'),
    //             description: this.$t('login-empty-reg'),
    //             type: 'error'
    //           })
    //         }
    //         else{
    //           Vue.toastr({
    //             message: this.$t('error'),
    //              description: this.$t('login-reg').replace('arrayRegions', e.body.region.join(', ')),
    //             type: 'error'
    //           })
    //         }
    //
    //
    //         this.iinLoading = true;
    //
    //       });
    // },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.account.phone = x[0];
    },
    registrationClosed() {
      return Vue.toastr({
        message: '',
        description: this.$t('registration_for_teachers_is_closed'),
        type: 'info'
      });
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading = true;
      this.loginTeacher(this.account)
          .then(() => {
            this.loading = false;
            //sessionStorage.removeItem('children');

            this.$router.push(this.$route.query.redirect || '/teacher-cabinet')
          })
          .catch((e) => {
            this.loading = false;
            if (e.body.message === 'Incorrect login or password') {
              Vue.toastr({
                message: 'Ошибка',
                description: this.$t('Incorrect login or password'),
                type: 'error'
              })
            }
            Vue.toastr({
              message: this.$t('signup.error-modal'),
              description: e.body.message,
              type: 'error'
            })
          })
    },
    ...mapActions({
      'getIin': 'getIin',
      'loginTeacher': 'loginTeacher'
    })
  },
  validations: {
    account: {
      email: {required, email},
      password: {
        required,
        minLength: minLength(8)
      }
    }
  }
}
</script>

<style>
.el-select-dropdown__empty {
  padding: 10px;
}
</style>